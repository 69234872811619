import React from "react";
import Hero from "../component/home/Hero";
import HowToPlay from "../component/home/HowToPlay";
import NotEveryOne from "../component/home/NotEveryOne";
import OurMordrenWorld from "../component/home/OurMordrenWorld";
import Team from "../component/home/Team";

const Home = () => {
  return (
    <>
      <Hero />
      <OurMordrenWorld />
      <NotEveryOne />
      <HowToPlay />
      <Team />
    </>
  );
};

export default Home;
