import logoimg from "../../assets/img/logo.png";
import demonImg from "../../assets/img/demon.png";
import shadowImg from "../../assets/img/shadow.png";

const NotEveryOne = () => {
  return (
    <div
      className="py-sm-5 py-3 not_everyone position-relative overflow-hidden"
      id="lore"
    >
      <div className="container container-modified ">
        <div className="row justify-content-center ">
          <div
            className="col-lg-6 pt-4 d-flex flex-column justify-content-center pt-lg-0 pe-xxl-5 overflow-hidden"
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            <h2 className="main-heading">
              <img
                className="w-100 heading-img me-2"
                src={logoimg}
                alt="logoimg"
              />
              Not everyone believed
              <span className="text-blue fw-bold"> in demons.</span>
            </h2>
            <p className="normal-para lh-28 mb-2 ">
              That is, until they entered our world, exposing the gods that
              oversee our reality. While these beings work in mysterious ways,
              humans are left to ponder their objectives.
            </p>
            <p className="normal-para lh-28 mb-2 ">
              The power of the gods is in your hands to use as you wish!
            </p>
            <p className="normal-para lh-28 mb-2 ">
              Now, humans and demons live side-by-side. While some of us try to
              exploit their power, others form righteous alliances.
            </p>
          </div>
          <div className="col-lg-5 col-sm-8 col-10 d-flex flex-column justify-content-center position-relative">
            <img
              className="w-100 p-4 demon_img"
              src={demonImg}
              alt="demonImg"
            />
            <img className="shadow-img" src={shadowImg} alt="shadowImg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotEveryOne;
