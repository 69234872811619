import React from "react";
import { withRouter } from "react-router-dom";
import FooterLogo from "../../assets/img/footer-logo.png";
import { FacebookImg, InstagramImg, TwitterImg, YoutubeImg } from "./Icons";

const Footer = ({ history }) => {
  const getCurrentYear = new Date().getFullYear();
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <img
                onClick={() => history.push("/")}
                className="footer-logo cursor-pointer"
                src={FooterLogo}
                alt="FooterLogo"
              />
              <p className="font-xsm mt-4 pt-xl-3">Follow us on</p>
              <div className="social-icons">
                <a
                  href="https://www.instagram.com/demonsdecktcg/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramImg />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCKv0AMz626nlhYrrHgo_rCA"
                  target="_blank"
                  rel="noreferrer"
                >
                  <YoutubeImg />
                </a>
                <a
                  href="https://www.facebook.com/DemonsDeckTCG"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookImg />
                </a>
                <a
                  href="https://twitter.com/DemonsDeckTCG"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterImg />
                </a>
              </div>
              <p className="mt-xl-5 pt-4 mb-3 font-xsm">
                <span>{getCurrentYear}</span> © All rights reserved by Big Five
                Games LLC
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(Footer);
