import React from "react";
import Nowliveon from "../../assets/img/Now-live-on.png";
import heroImg from "../../assets/img/hero-img.png";
import Header from "../common/Header";
import { NowLiveOn, TextSvg } from "../common/Icons";

const Hero = () => {
  return (
    <div className="hero_section" id="home">
      <Header />
      <div className="container hero_section_content">
        <div className="row justify-content-center">
          <div
            className="col-md-7 col-sm-9 col-11 text-center"
            data-aos="zoom-in"
            data-aos-duration="2000"
            data-aos-delay="200"
          >
            <img className="w-100 mb-xxl-3" src={heroImg} alt="heroImg" />
            <a
              href="https://www.kickstarter.com/projects/demonsdeck/demons-deck-trading-card-game"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="now-live-hover ">
                <img
                  className="w-100 commin_soon mt-5"
                  src={Nowliveon}
                  alt="Nowliveon"
                />
                <div className="mt-4">
                  <TextSvg />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
