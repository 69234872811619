import React, { useEffect, useState } from "react";
import CardButtons from "../component/card/CardButtons";
import CardHero from "../component/card/CardHero";
import CardItem from "../component/card/CardItem";
import notFoundImg from "../assets/img/Demons Deck.png";
import { cardsdata } from "../component/common/Helper";

const Cards = () => {
  const [activeDemon, setActiveDemon] = useState("All");
  const [filterDemon, setFilterDemon] = useState(cardsdata);
  const [isSearch, setSearch] = useState(false);
  const [filteredCard, setFilteredCard] = useState([]);

  const filterHandler = (value) => {
    if (value === "All") {
      setFilterDemon(cardsdata);
    } else {
      setFilterDemon(() => {
        return cardsdata.filter((obj) => obj.cardtype === value);
      });
    }
  };
  const demonFilterHandler = (value) => {
    setActiveDemon(value);
    filterHandler(value);
  };

  useEffect(() => {
    filterHandler(activeDemon);
  }, [activeDemon]);

  const handleChangeInput = (e) => {
    if (e.target.value.length > 0) {
      setActiveDemon("All");
      const filterData = cardsdata.filter((obj) => {
        return obj.cardname
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      });
      setFilteredCard(filterData);
      setSearch(true);
    } else {
      setSearch(false);
    }
  };

  return (
    <>
      <CardHero handleChangeInput={handleChangeInput} />
      <CardButtons
        demonFilterHandler={demonFilterHandler}
        activeDemon={activeDemon}
      />
      <div className="container container-modified py-5">
        <div className="row">
          {/* IF IS SEARCH TRUE THEN SHOW FILTERED DATA FALSE  */}
          {isSearch ? (
            filteredCard && filteredCard.length > 0 ? (
              filteredCard.map((card, index) => (
                <CardItem card={card} key={index} />
              ))
            ) : (
              <img className="w-100" src={notFoundImg} alt="notFoundImg" />
            )
          ) : filterDemon && filterDemon.length > 0 ? (
            filterDemon.map((card, index) => (
              <CardItem card={card} key={index} />
            ))
          ) : (
            <img className="w-100" src={notFoundImg} alt="notFoundImg" />
          )}
        </div>
      </div>
    </>
  );
};

export default Cards;
