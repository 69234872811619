import React from "react";
import { useMediaQuery } from "react-responsive";
import { withRouter } from "react-router-dom";
import { headerData } from "./Helper";
const Header = ({ history }) => {
  const BeforeDesktop = ({ children }) => {
    const isBeforeDesktop = useMediaQuery({ maxWidth: 991.98 });
    return isBeforeDesktop ? children : null;
  };
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };
  const handldeOverlayActive = () => {
    document.body.classList.toggle("active-nav-overlay");
  };

  const pathName = window.location.pathname;

  return (
    <>
      <nav className="navbar-wrapper w-100 position-relative ">
        <Desktop>
          <div className="container d-flex justify-content-between align-items-center  py-2 py-xxl-3 position-relative">
            <div className="nav-list-items w-100">
              <ul className="list-unstyled d-flex align-items-center justify-content-center m-0">
                {headerData.map((obj, index) => (
                  <li key={index} className="px-2">
                    {pathName === "/cards" ? (
                      <a
                        href={obj.path}
                        className="text-decoration-none navbar-link text-nowrap"
                        onClick={() => history.push(obj.pathPush)}
                      >
                        {obj.link}
                      </a>
                    ) : (
                      <>
                        {index === 5 ? (
                          <span
                            className="text-decoration-none navbar-link text-nowrap"
                            onClick={() => history.push(obj.pathPush)}
                          >
                            {obj.link}
                          </span>
                        ) : (
                          <a
                            href={obj.path}
                            className="text-decoration-none navbar-link text-nowrap"
                          >
                            {obj.link}
                          </a>
                        )}
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Desktop>
        <BeforeDesktop>
          <div className=" container  ">
            <div className="py-2 px-sm-3 d-flex justify-content-lg-between justify-content-end align-items-center ">
              <div
                id="nav-icon1"
                className={`hamburger-menu`}
                onClick={() => handldeOverlayActive()}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
          <div className={`navbar-overlay  `}>
            <ul className="h-100 list-unstyled d-flex flex-column align-items-center justify-content-center m-0">
              {headerData.map((obj, index) => (
                <li key={index} onClick={() => handldeOverlayActive()}>
                  {pathName === "/cards" ? (
                    <a
                      href={obj.path}
                      className="text-decoration-none navbar-link text-nowrap"
                      onClick={() => history.push(obj.pathPush)}
                    >
                      {obj.link}
                    </a>
                  ) : (
                    <>
                      {index === 5 ? (
                        <span
                          className="text-decoration-none navbar-link text-nowrap"
                          onClick={() => history.push(obj.pathPush)}
                        >
                          {obj.link}
                        </span>
                      ) : (
                        <a
                          href={obj.path}
                          className="text-decoration-none navbar-link text-nowrap"
                        >
                          {obj.link}
                        </a>
                      )}
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </BeforeDesktop>
      </nav>
    </>
  );
};
export default withRouter(Header);
