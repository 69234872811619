import logoimg from "../../assets/img/logo.png";
const OurMordrenWorld = () => {
  return (
    <div className="py-sm-5 py-3 modren_world overflow-hidden" id="overview">
      <div className="container container-modified py-sm-5">
        <div className="row justify-content-center py-sm-5 pt-5">
          <div className="col-lg-6 col-sm-8 col-10 d-flex flex-column justify-content-center align-items-center text-center">
            <div className="cards_rotation_animation">
              <div className="cards_animation">
                <div className="card_change_animation"></div>
              </div>
              <div className="cards_animation2">
                <div className="card_change_animation2"></div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 pt-4 pt-lg-0 d-flex flex-column justify-content-center"
            data-aos="fade-left"
            data-aos-duration="2000"
          >
            <h2 className="main-heading">
              <img className="w-100 heading-img" src={logoimg} alt="logoimg" />
              Our modern world has been engulfed
              <span className="text-blue fw-bold"> by divine forces.</span>
            </h2>
            <p className="normal-para lh-28 mb-2 ">
              Harness your devotion and the power of demons to fight for your
              god!
            </p>
            <p className="normal-para lh-28 mb-2 ">
              Demon’s Deck is a game where you command legions of followers on
              behalf of powerful deities.
            </p>
            <p className="normal-para lh-28 ">
              Build a following for your god by collecting and deploying
              dastardly demons and human servants in a spiritual battle for
              divine supremacy. Mix and match your cards to create the strategy
              to defeat your foes, and build your ultimate Demon Deck!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMordrenWorld;
