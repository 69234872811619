import React from "react";
import Footer from "./Footer";
import JoinUs from "./JoinUs";

const BottomPart = () => {
  return (
    <>
      <section className="footer">
        <JoinUs />
        <Footer />
      </section>
    </>
  );
};

export default BottomPart;
