import leftImg from "..//../assets/img/join-us-left-shadow.webp";
import rightImg from "..//../assets/img/join-us-right-shaddow.webp";
import React from "react";

const JoinUs = () => {
  return (
    <>
      <section className="join-us position-relative">
        <img
          className="position-absolute left-img w-100"
          src={leftImg}
          alt="leftImg"
        />
        <img
          className="position-absolute right-img w-100"
          src={rightImg}
          alt="rightImg"
        />
        <div className="container mb-sm-5 py-5">
          <div className="row justify-content-center my-sm-5 py-xxl-5">
            <div
              className="col-12 col-sm-8 col-lg-6 text-center z-2"
              data-aos="zoom-in"
              data-aos-duration="2600"
            >
              <h2 className="font-xxl fw-700 mb-2 mx-xxl-5 mt-xxl-5">
                Join us on our <span className="text-blue">Discord</span>
              </h2>

              <a
                className="d-inline-block pt-4"
                href="https://discord.com/invite/demonsdecktcg"
                target="_blank"
                rel="noreferrer"
              >
                <button className="blue-btn">Join Now</button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JoinUs;
