import React from "react";
import Header from "../common/Header";
import heroImg from "../../assets/img/hero-img.png";

const CardHero = ({ handleChangeInput }) => {
  return (
    <div className="cards_hero">
      <Header />
      <div className="container container-modified py-5">
        <div className="row py-lg-5 ">
          <div className="col-lg-6 col-sm-8">
            <img
              className="w-100"
              src={heroImg}
              alt="heroImg"
              data-aos="fade-left"
              data-aos-delay="100"
            />
            <div className="pt-5" data-aos="fade-right" data-aos-delay="100">
              <input
                onChange={(e) => handleChangeInput(e)}
                className="cards_input"
                type="search"
                placeholder="Search Card"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardHero;
