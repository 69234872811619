import React, { useState } from "react";
import CardModal from "./CardModal";

const CardItem = ({ card }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="col-lg-3 z-2 text-center  col-md-4 col-sm-6  mb-3 pt-3">
        <img
          className="w-100 mw-265 translate_hover"
          onClick={handleShow}
          src={card.imagePath}
          alt={card.cardname}
        />
      </div>
      <CardModal handleClose={handleClose} show={show} card={card} />
    </>
  );
};

export default CardItem;
