import { useState, useEffect } from "react";
import { UpArrow } from "./Icons";

export default function GoTop() {
  const [isVisible, setIsVisible] = useState(false);
  const [pageYOffset, setPageYOffset] = useState(0);

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", function () {
      setPageYOffset(window.pageYOffset);
    });
  }, []);

  useEffect(() => {
    if (pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [pageYOffset]);

  return (
    <div className="back-to-top">
      {isVisible && (
        <div onClick={() => scrollToTop()}>
          <UpArrow />
        </div>
      )}
    </div>
  );
}
