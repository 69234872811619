import React from "react";
import { Fade, Modal } from "react-bootstrap";

const CardModal = ({ show, handleClose, card }) => {
  return (
    <>
      <Modal
        show={show}
        size="xl"
        scrollable
        centered
        transition={Fade}
        onHide={handleClose}
        className="Scrollbar_modal"
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <div className="container pt-4 pb-5 mb-sm-4">
            <div className="row justify-content-center justify-content-md-evenly align-items-center">
              <div className="col-9 text-center col-sm-8 col-md-5">
                <img
                  className="w-100 mw-390"
                  src={card.imagePath}
                  alt="modalImg"
                />
              </div>
              <div className="col-9 col-sm-8 col-md-6 pt-4 pt-lg-0">
                <div className="d-flex">
                  <p className="font-sm fw-700 me-3 mb-2 mb-lg-2">Card Name:</p>
                  <p className="font-sm opacity-75 mb-2 mb-lg-2">
                    {card.cardname}
                  </p>
                </div>
                <div className="d-flex">
                  <p className="font-sm fw-700 me-4 mb-2 mb-lg-2">Card Type:</p>
                  <p className="font-sm opacity-75 mb-2 mb-lg-2">
                    {card.cardtype}
                  </p>
                </div>
                <div className="d-flex">
                  <p className="font-sm fw-700 me-5  pe-3 mb-2 mb-lg-2">
                    Faith
                  </p>
                  <p className="font-sm opacity-75 mb-2 mb-lg-2">
                    {card.faith}
                  </p>
                </div>
                <div className="d-flex">
                  <p className="font-sm fw-700 me-4 pe-4 mb-2 mb-lg-2">ATK</p>
                  <p className="font-sm opacity-75 ps-3 ms-1 mb-2 mb-lg-2">
                    {card.ATT}
                  </p>
                </div>

                <div className="d-flex">
                  <p className="font-sm fw-700 me-3 mb-2 me-4 pe-4  mb-lg-2">
                    DEF
                  </p>
                  <p className="font-sm opacity-75 mb-2 mb-lg-2 ps-3 ms-1">
                    {card.DEF}
                  </p>
                </div>
                <div className="d-flex">
                  <p className="font-sm fw-700 me-5 pe-1 mb-2 mb-lg-2">
                    Rarity
                  </p>
                  <p className="font-sm fw-400 mb-2 mb-lg-2">{card.Rarity}</p>
                </div>
                <div className="d-flex flex-column flex-md-row">
                  <p className="font-sm fw-700 me-4 mb-2 mb-lg-2">
                    Effect Text
                  </p>
                  <p className="font-sm fw-400 modal-para mb-2 mb-lg-2">
                    {card.effecttext}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CardModal;
