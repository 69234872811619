import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import BottomPart from "./component/common/BottomPart";
import GoTop from "./component/common/GoTop";
import Cards from "./view/Cards";
import Home from "./view/Home";

const AppRoutes = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/cards" component={Cards} />
        </Switch>
        <BottomPart />
        <GoTop />
      </Router>
    </>
  );
};

export default AppRoutes;
