import playImg from "../../assets/img/HOW-TO-PLAY.png";
import logoimg from "../../assets/img/logo.png";
import { howTpPlayData } from "../common/Helper";

const HowToPlay = () => {
  return (
    <div
      className="py-sm-5 py-3 how_to_play position-relative ovrflw-x"
      id="how-to-play"
    >
      <div className="container container-modified py-5">
        <div className="row justify-content-center ">
          <div
            className="col-lg-6 col-sm-8 col-15 d-flex flex-column justify-content-center position-relative"
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            <img
              className="w-100 p-sm-4 how_to_play_img"
              src={playImg}
              alt="demonImg"
            />
          </div>
          <div
            className="col-lg-6 pt-4 d-flex flex-column justify-content-center pt-lg-0 pe-xxl-5"
            data-aos="fade-left"
            data-aos-duration="2000"
          >
            <h2 className="main-heading">
              <img className="w-100 heading-img " src={logoimg} alt="logoimg" />
              <span className="text-blue fw-bold"> How </span>to Play
            </h2>
            <p className="normal-para  lh-28 ps-1">
              Demon’s Deck is a trading card game where nimble planning and
              careful gameplay can create table-turning moments.
            </p>
            {howTpPlayData.map((obj, index) => (
              <div
                key={index}
                className="d-flex align-items-center pb-3 pt-2 lh-28 fill_blue_on_hover"
              >
                <h2 className="pe-3 mb-0">
                  <span className="round_circle">{obj.number}</span>
                </h2>
                <p className="normal-para mb-0">{obj.disc}</p>
              </div>
            ))}
            <div className="d-flex flex-column flex-sm-row align-items-center mt-4">
              <a
                href="https://demonsdeck.com/Demons_Deck_Rules.pdf"
                target="_blank"
                rel="noreferrer"
                className="blue-btn text-decoration-none w-xs-100 text-center"
              >
                Rules
              </a>
              <a
                className="blue-btn mt-3 mt-sm-0 ms-sm-5 text-decoration-none w-xs-100 text-center"
                href="https://www.youtube.com/watch?v=Ab6AM9DZeH0"
                target="_blank"
                rel="noreferrer"
              >
                How to Play Video
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToPlay;
