import React from "react";
import logoimg from "../../assets/img/logo.png";
import { teamData, withhelpfromteamData } from "../common/Helper";

const Team = () => {
  return (
    <div
      className="team_section py-sm-5 py-3 position-relative overflow-hidden"
      id="team"
    >
      <div className="container container-modified py-xxl-5">
        <div className="row ">
          <div
            className="col-lg-6 d-flex flex-column justify-content-center"
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            <h2 className="main-heading">
              <img
                className="w-100 heading-img me-2 "
                src={logoimg}
                alt="logoimg"
              />
              Meet
              <span className="text-blue fw-bold"> the Team </span>
            </h2>
          </div>
          <div
            className="col-lg-6 col-12"
            data-aos="fade-left"
            data-aos-duration="2000"
          >
            <p className="normal-para lh-28">
              As a group we have had our hands in a wide variety of media
              industries, and our team has harnessed their various skills and
              expertises and brought them to bear upon the task of creating the
              ultimate trading card game. Intent on rewarding both players and
              collectors alike, we’ve put together what we believe to be the
              most exciting TCG around—Welcome to Demon’s Deck!
            </p>
          </div>
        </div>

        <div className="row position-relative z-2">
          {teamData.map((obj, index) => (
            <div key={index} className="col-lg-6 pt-5">
              <div
                className="team_card"
                data-aos-delay={`${(index + 1) * 100}`}
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <div className="team_img_container">
                  <img
                    className="w-100  mw-164"
                    src={obj.imgUrl}
                    alt="team-img"
                  />
                </div>
                <div className="px-sm-3 ms-sm-2 pt-4 pt-sm-0">
                  <h2>{obj.heading}</h2>
                  <h3 className="normal-para">{obj.subHeading}</h3>
                  <p className="normap-para mb-0 lh-20">{obj.disc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="row position-relative z-2 mt-5 ">
          <h2 className="main-heading py-sm-3">
            <img
              className="w-100 heading-img me-2 "
              src={logoimg}
              alt="logoimg"
            />
            With
            <span className="text-blue fw-bold"> help from </span>
          </h2>

          {withhelpfromteamData.map((obj, index) => (
            <div key={index} className="col-lg-6 pt-5">
              <div
                className="team_card"
                data-aos-delay={`${(index + 1) * 100}`}
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <div className="team_img_container">
                  <img
                    className="w-100  mw-164"
                    src={obj.imgUrl}
                    alt="team-img"
                  />
                </div>
                <div className="px-sm-3 ms-sm-2 pt-4 pt-sm-0">
                  <h2>{obj.heading}</h2>
                  <div
                    className="normal-para"
                    dangerouslySetInnerHTML={{ __html: obj.subHeading }}
                  />
                  <p className="normap-para mb-0 lh-20">{obj.disc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
