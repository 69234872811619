import React from "react";
import { buttonData } from "../common/Helper";

const CardButtons = ({ demonFilterHandler, activeDemon }) => {
  return (
    <>
      <div className="container container-modified pt-5 position-relative z-5">
        <div className="d-lg-flex align-items-center justify-content-between  mt-xl-5">
          <div
            className="scroll_overflow"
            data-aos="fade-left"
            data-aos-delay="200"
          >
            {buttonData.map((obj, index) => (
              <button
                key={index}
                className={`cards_button me-3 mb-3 ${
                  obj.text === activeDemon ? "tab-btn-active" : ""
                }`}
                onClick={() => demonFilterHandler(obj.text)}
              >
                {obj.text}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardButtons;
