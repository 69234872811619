import EvertrapEgg from "../../assets/img/cardpngs/artifact-1.png";
import SanguineShield from "../../assets/img/cardpngs/artifact-2.png";
import VitalityVial from "../../assets/img/cardpngs/artifact-4.png";
import SerafyliasMirror from "../../assets/img/cardpngs/artifcat-3.png";
import CuriosityShop from "../../assets/img/cardpngs/domain-1.png";
import Demonstorm from "../../assets/img/cardpngs/domain-2.png";
import Meadow from "../../assets/img/cardpngs/domain-3.png";
import MindbreakTower from "../../assets/img/cardpngs/domain-4.png";
import PrivateRitualChamber from "../../assets/img/cardpngs/domain-5.png";
import TheApexAssembly from "../../assets/img/cardpngs/domain-6.png";
import DivineAscension from "../../assets/img/cardpngs/event-1.png";
import FeverishFumes from "../../assets/img/cardpngs/event-2.png";
import GoblinCrateDelivery from "../../assets/img/cardpngs/event-3.png";
import KnifeThrow from "../../assets/img/cardpngs/event-4.png";
import MutualDestruction from "../../assets/img/cardpngs/event-5.png";
import Petrify from "../../assets/img/cardpngs/event-6.png";
import PotentEmpathy from "../../assets/img/cardpngs/event-7.png";
import TheRichGetRicher from "../../assets/img/cardpngs/event-8.png";
import TacticalSwitch from "../../assets/img/cardpngs/event-9.png";
import ArdemolGodofSacrifice from "../../assets/img/cardpngs/god-1.png";
import HakaiGodofWrath from "../../assets/img/cardpngs/god-2.png";
import MobiusGodofSpaceTime from "../../assets/img/cardpngs/god-3.png";
import SerafyliaGodofSeduction from "../../assets/img/cardpngs/god-4.png";
import ApexResearcher from "../../assets/img/cardpngs/servant-1.png";
import PickpocketImp from "../../assets/img/cardpngs/servant-10.png";
import TheFailedExperiment from "../../assets/img/cardpngs/servant-11.png";
import SinisterSister from "../../assets/img/cardpngs/servant-12.png";
import UnassumingWorshipper from "../../assets/img/cardpngs/servant-13.png";
import VileGut from "../../assets/img/cardpngs/servant-14.png";
import ArmchairDemonologist from "../../assets/img/cardpngs/servant-2.png";
import BlackCat from "../../assets/img/cardpngs/servant-3.png";
import BorrowedBones from "../../assets/img/cardpngs/servant-4.png";
import FrenziedLandshark from "../../assets/img/cardpngs/servant-5.png";
import GrowingProcession from "../../assets/img/cardpngs/servant-6.png";
import Hellgramite from "../../assets/img/cardpngs/servant-7.png";
import MacetailMink from "../../assets/img/cardpngs/servant-8.png";
import MirrorImage from "../../assets/img/cardpngs/servant-9.png";
import team1 from "../../assets/img/team-1.webp";
import team2 from "../../assets/img/team-2.webp";
import team4 from "../../assets/img/team-4.webp";
import team5 from "../../assets/img/team-5.webp";
import team6 from "../../assets/img/team-6.webp";

// CARDS  ARRAY
export const cardsdata = [
  {
    id: 1,
    imagePath: SanguineShield,
    cardtype: "Artifact",
    cardname: "Sanguine Shield",
    faith: 3,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Rare",
    effecttext:
      "Sacrificial (This artifact's cost is paid in life, not faith) Activate when your servant in this lane would be attacked. Effect: Servants attacking your servant in this lane deal damage to themselves equal to their attack.",
  },
  {
    id: 2,
    imagePath: SerafyliasMirror,
    cardtype: "Artifact",
    cardname: "Serafylia's Mirror",
    faith: 3,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Uncommon",
    effecttext:
      "Activate when you gain life. Effect: Deal damage to a servant in this lane equal to the amount of life gained.",
  },
  {
    id: 3,
    imagePath: EvertrapEgg,
    cardtype: "Artifact",
    cardname: "Everhatch Egg",
    faith: 1,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Common",
    effecttext:
      "Activate when an opponent plays a servant. Effect: Summon a servant from your hand that costs 1 faith or less at no cost",
  },
  {
    id: 4,
    imagePath: VitalityVial,
    cardtype: "Artifact",
    cardname: "Vitality Vial",
    faith: 1,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Uncommon",
    effecttext:
      "Activate when a source of damage reduces you to 1 life. Effect: Set your life to 10.",
  },
  {
    id: 5,
    imagePath: TheApexAssembly,
    cardtype: "Domain",
    cardname: "The Apex Assembly",
    faith: 10,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Demonic",
    effecttext:
      "Whenever a servant is sent to purgatory, its owner may summon a servant from their hand in its place. Servants summoned this way cost life points equal to the card's faith cost.",
  },
  {
    id: 6,
    imagePath: Meadow,
    cardtype: "Domain",
    cardname: "Meadow",
    faith: 1,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Uncommon",
    effecttext: "Sacred. (Sacred cards can replace cards of any faith cost.)",
  },
  {
    id: 7,
    imagePath: CuriosityShop,
    cardtype: "Domain",
    cardname: "Curiosity Shop",
    faith: 3,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Uncommon",
    effecttext: "Whenever an artifact you control activates, draw a card.",
  },
  {
    id: 8,
    imagePath: Demonstorm,
    cardtype: "Domain",
    cardname: "Demonstorm",
    faith: 7,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Uncommon",
    effecttext: "When this domain is played, deal 5 damage to all servants.",
  },
  {
    id: 9,
    imagePath: MindbreakTower,
    cardtype: "Domain",
    cardname: "Mindbreak Tower",
    faith: 3,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Common",
    effecttext: `When "Mind Disruption Spire" enters play, target servant gets -2/-2 for as long as this domain is in play.`,
  },
  {
    id: 10,
    imagePath: PrivateRitualChamber,
    cardtype: "Domain",
    cardname: "Private Ritual Chamber",
    faith: 4,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Common",
    effecttext:
      "At the start of your turn, you may deal 1 damage to any target.",
  },
  {
    id: 11,
    imagePath: TacticalSwitch,
    cardtype: "Event",
    cardname: "Tactical Switch",
    faith: 2,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Common",
    effecttext:
      "Target two different servants you control, then Swap their positions.",
  },
  {
    id: 12,
    imagePath: FeverishFumes,
    cardtype: "Event",
    cardname: "Feverish Fumes",
    faith: 2,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Common",
    effecttext:
      "Target servant loses all effect text and gains +2/+0 until end of turn.",
  },
  {
    id: 13,
    imagePath: MutualDestruction,
    cardtype: "Event",
    cardname: "Mutual Destruction",
    faith: 4,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Common",
    effecttext:
      "Each player destroys an artifact or domain under their control if able.",
  },
  {
    id: 14,
    imagePath: TheRichGetRicher,
    cardtype: "Event",
    cardname: "The Rich Get Richer",
    faith: 4,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Common",
    effecttext: "Draw 2 cards.",
  },
  {
    id: 15,
    imagePath: DivineAscension,
    cardtype: "Event",
    cardname: "Divine Ascension",
    faith: 10,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Rare",
    effecttext: "Target servant doubles its attack until end of turn.",
  },
  {
    id: 16,
    imagePath: GoblinCrateDelivery,
    cardtype: "Event",
    cardname: "Goblin Crate Delivery",
    faith: 3,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Common",
    effecttext: "Summon a 1/1 token servant. Gain one life. Draw one card.",
  },
  {
    id: 17,
    imagePath: KnifeThrow,
    cardtype: "Event",
    cardname: "Knife Throw",
    faith: 2,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Common",
    effecttext: "Deal 2 damage to any target.",
  },
  {
    id: 18,
    imagePath: Petrify,
    cardtype: "Event",
    cardname: "Petrify",
    faith: 6,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Rare",
    effecttext:
      "Target a servant with an empty position behind it, Move target servant backwards one position. It becomes a domain with no effect, retaining the same faith cost.",
  },
  {
    id: 19,
    imagePath: PotentEmpathy,
    cardtype: "Event",
    cardname: "Potent Empathy",
    faith: 3,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Common",
    effecttext:
      "Deal 2 damage to target servant, and 1 damage to each adjacent servant.",
  },
  {
    id: 20,
    imagePath: ArdemolGodofSacrifice,
    cardtype: "God",
    cardname: "Ardemol, God of Sacrifice",
    faith: "N/A",
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Uncommon",
    effecttext:
      "[5 Life] Give a servant you control a Devotion charge. When a servant you control with a Devotion charge dies, place it on top of your deck.",
  },
  {
    id: 21,
    imagePath: HakaiGodofWrath,
    cardtype: "God",
    cardname: "Hakai, God of Wrath",
    faith: "N/A",
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Uncommon",
    effecttext:
      "Servants you control have +3 attack and a base defense of 1 while in the middle lane.",
  },
  {
    id: 22,
    imagePath: SerafyliaGodofSeduction,
    cardtype: "God",
    cardname: "Serafylia, God of Seduction",
    faith: "N/A",
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Common",
    effecttext:
      "If you have no unspent faith at the end of your turn, gain 2 life.",
  },

  {
    id: 23,
    imagePath: MobiusGodofSpaceTime,
    cardtype: "God",
    cardname: "Mobius, God of Space-Time",
    faith: "N/A",
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Uncommon",
    effecttext:
      "Your leftmost and rightmost lanes are considered adjacent for purposes of Shift and card effects.",
  },
  {
    id: 24,
    imagePath: GrowingProcession,
    cardtype: "Servant",
    cardname: "Growing Procession",
    faith: 1,
    ATT: 1,
    DEF: 1,
    Rarity: "Rare",
    effecttext: `"Growing Procession" gains a +1/+1 charge whenever it changes positions..`,
  },
  {
    id: 25,
    imagePath: TheFailedExperiment,
    cardtype: "Servant",
    cardname: "The Failed Experiment",
    faith: 6,
    ATT: 6,
    DEF: 6,
    Rarity: "Rare",
    effecttext: `"The Failed Experiment" is destroyed at the start of your turn. Gain 6 life when "The Failed Experiment" is destroyed in this way.`,
  },
  {
    id: 26,
    imagePath: PickpocketImp,
    cardtype: "Servant",
    cardname: "Pickpocket Imp",
    faith: 2,
    ATT: 3,
    DEF: 1,
    Rarity: "Uncommon",
    effecttext: `When "Pickpocket Imp" kills another servant, gain 1 faith until end of turn.`,
  },
  {
    id: 27,
    imagePath: ApexResearcher,
    cardtype: "Servant",
    cardname: "Apex Researcher",
    faith: 1,
    ATT: 1,
    DEF: 1,
    Rarity: "Uncommon",
    effecttext: `When "Apex Researcher" attacks, look at the top two cards of your deck, then put them back on top in any order.`,
  },
  {
    id: 28,
    imagePath: UnassumingWorshipper,
    cardtype: "Servant",
    cardname: "Unassuming Worshipper",
    faith: 1,
    ATT: 0,
    DEF: 1,
    Rarity: "Uncommon",
    effecttext: `[3 Faith]: You may banish "Unassuming Worshipper" from your purgatory to summon a 3/3 token servant. Activate this only during your turn.`,
  },

  {
    id: 29,
    imagePath: FrenziedLandshark,
    cardtype: "Servant",
    cardname: "Frenzied Landshark",
    faith: 4,
    ATT: 3,
    DEF: 3,
    Rarity: "Common",
    effecttext: `Frenzied Landshark" has +2/+0 while the position behind it is empty.`,
  },
  {
    id: 30,
    imagePath: ArmchairDemonologist,
    cardtype: "Servant",
    cardname: "Armchair Demonologist",
    faith: 3,
    ATT: 2,
    DEF: 4,
    Rarity: "Common",
    effecttext: `When "Armchair Demonologist" changes positions, send it to its owner's purgatory.`,
  },
  {
    id: 31,
    imagePath: BlackCat,
    cardtype: "Servant",
    cardname: "Black Cat",
    faith: 2,
    ATT: 2,
    DEF: 1,
    Rarity: "Common",
    effecttext:
      "When this servant leaves the battlefield or is discarded, deal 2 damage to any target.",
  },
  {
    id: 32,
    imagePath: BorrowedBones,
    cardtype: "Servant",
    cardname: "Borrowed Bones",
    faith: 5,
    ATT: 2,
    DEF: 5,
    Rarity: "Rare",
    effecttext:
      "At the start of combat, if this servant is not attacking, target adjacent servant gains +X/+0 until end of combat, where X is this servant's attack.",
  },
  {
    id: 33,
    imagePath: Hellgramite,
    cardtype: "Servant",
    cardname: "Hellgramite",
    faith: 4,
    ATT: 1,
    DEF: 3,
    Rarity: "Uncommon",
    effecttext:
      "When an artifact activates in this lane, negate the activation and destroy the artifact.",
  },
  {
    id: 34,
    imagePath: MacetailMink,
    cardtype: "Servant",
    cardname: "Macetail Mink",
    faith: 2,
    ATT: 3,
    DEF: 1,
    Rarity: "Common",
    effecttext: `At the start of combat, if "Macetail Mink" is attacking, Shift (Move this Servant left or right one position as long as it is unoccupied).`,
  },
  {
    id: 35,
    imagePath: MirrorImage,
    cardtype: "Servant",
    cardname: "Mirror Image",
    faith: 5,
    ATT: 4,
    DEF: 3,
    Rarity: "Common",
    effecttext: `When this servant is killed, summon a 4/1 "Illusionist" token servant in its place.`,
  },
  {
    id: 36,
    imagePath: SinisterSister,
    cardtype: "Servant",
    cardname: "Sinister Sister",
    faith: 1,
    ATT: "N/A",
    DEF: "N/A",
    Rarity: "Demonic",
    effecttext: `This servant's attack is equal to that of the servant to its left. This servant's defense is equal to that of the servant to its right.`,
  },
  {
    id: 37,
    imagePath: VileGut,
    cardtype: "Servant",
    cardname: "Vile Gut",
    faith: 6,
    ATT: 6,
    DEF: 4,
    Rarity: "Common",
    effecttext: `When "Vile Gut" deals more damage to an opposing servant than it has defense, destroy any opposing domains or artifacts in this lane.`,
  },
];

export const headerData = [
  {
    link: "Home",
    path: "#home",
    pathPush: "/",
  },
  {
    link: "Overview",
    path: "#overview",
    pathPush: "/",
  },
  {
    link: "Lore",
    path: "#lore",
    pathPush: "/",
  },
  {
    link: "How to Play",
    path: "#how-to-play",
    pathPush: "/",
  },
  {
    link: "Team",
    path: "#team",
    pathPush: "/",
  },
  {
    link: "Cards",
    path: "",
    pathPush: "/cards",
  },
];
export const howTpPlayData = [
  {
    number: "1",
    disc: "Choose a God to serve, aiding you with powerful passive and active effects for the duration of the game.",
  },
  {
    number: "2",
    disc: "Summon powerful Servants and control Domains using Faith, a resource that replenishes and grows over the course of the game, enabling even bigger plays.",
  },
  {
    number: "3",
    disc: "Jump into the fray on a three-lane field. Positioning is key. Will you focus on one lane or try to conquer all three?",
  },
  {
    number: "4",
    disc: "Surprise your opponent with mysterious Artifacts and unforeseen Events that can react to their moves.",
  },
];
export const teamData = [
  {
    imgUrl: team1,
    heading: "Alex Roy ",
    subHeading: "CEO",
    disc: "Alex is a long time trading card collector, and also trading card game store retailer. He has 6 years experience as a CEO and has experience working with world wide leading vendors.",
  },

  {
    imgUrl: team4,
    heading: "May Gardens",
    subHeading: "Lead Game Designer",
    disc: "May has been making games in some shape or form for most of her life, and in a professional capacity for nearly a decade. Her award-nominated work has been featured on sites such as Ars Technica, for her skill with (and love for) tight systems and the gameplay that emerges from them.",
  },
  {
    imgUrl: team5,
    heading: "Heath Newton",
    subHeading: "Game Designer",
    disc: "Heath is a game designer and writer, with experience in both video and tabletop games. With a burning passion for the inner workings of gameplay mechanics and creative ways to implement them into stories and settings, he has been building worlds, writing reviews, and tinkering on games since he was a child.",
  },
];

export const withhelpfromteamData = [
  {
    imgUrl: team2,
    heading: "Versiris  ",
    subHeading:
      "Concept Artist- <br /> Established the games visual style, card design, and first illustrations",
    disc: "Versiris is an independent artist and YouTuber known for her video game inspired animations. With various clients across the gaming and animation industries, her work has been featured by Sony, Blizzard, Fangamer and more.",
  },

  {
    imgUrl: team6,
    heading: "James Avestruz",
    subHeading:
      "Video Production- <br /> Assisted with video production and additional writing",
    disc: "James has been an online content creator for over 15 years. As a video producer his work has been featured by worldwide clients in multiple business industries. He works as a wedding photographer as well as a voice actor for video games and animations.",
  },
];
export const buttonData = [
  {
    text: "All",
  },
  {
    text: "Artifact",
  },
  {
    text: "Domain",
  },
  {
    text: "Event",
  },
  {
    text: "God",
  },
  {
    text: "Servant",
  },
];
