import React from "react";
import preloading from "../../assets/img/footer-logo.png";

const Loader = () => {
  return (
    <>
      <div className="loader-fullscreen">
        <img className="loader-img" src={preloading} alt="heroSnailImage" />
      </div>
    </>
  );
};

export default Loader;
